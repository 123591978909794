import { useCallback, useEffect, Suspense } from 'react';

import { Flex } from '@nestoca/ui';
import cn from 'classnames';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
    applicationListState,
    currentApplicationIdState,
} from 'store/applications';

import { ApplicationSelectorBody } from './application-selector-body';
import { BodySkeleton } from './application-selector-body/skeleton';
import { ApplicationSelectorFooter } from './application-selector-footer';
import { ApplicationSelectorHeader } from './application-selector-header';
import styles from './application-selector.module.scss';

type ApplicationSelectorProps = {
    outsideOfModal?: boolean;
};

export const ApplicationSelector = ({
    outsideOfModal = false,
}: ApplicationSelectorProps) => {
    const applications = useRecoilValue(applicationListState);

    const setCurrentApplicationId = useSetRecoilState(
        currentApplicationIdState
    );

    const handleSetCurrentApplicationId = useCallback(
        (applicationId: number) => {
            setCurrentApplicationId(applicationId);

            localStorage.setItem(
                'OfficeAuth:applicationId',
                `${applicationId}`
            );
        },
        [setCurrentApplicationId]
    );

    useEffect(() => {
        // If we only have one application, we select it automatically
        if (applications.length === 1) {
            const application = applications[0];

            handleSetCurrentApplicationId(application.id);
        }
    }, [applications, handleSetCurrentApplicationId]);

    return (
        <Flex
            id="application-selector"
            className={cn(styles['application-selector'], {
                [styles['application-selector--outside-of-modal']]:
                    outsideOfModal,
            })}
            direction="column"
        >
            <ApplicationSelectorHeader />
            <Suspense fallback={<BodySkeleton />}>
                <ApplicationSelectorBody
                    applications={applications}
                    handleSetCurrentApplicationId={
                        handleSetCurrentApplicationId
                    }
                />
            </Suspense>
            <ApplicationSelectorFooter />
        </Flex>
    );
};
