import { ApplicationSelector } from 'components/application-selector';
import { Modal } from 'components/modal';
import { useModal } from 'providers/modals/use-modal';

import styles from './application-selector-modal.module.scss';

export const ApplicationSelectorModal = () => {
    const {
        isVisible: isApplicationSelectorModalVisible,
        close: closeApplicationSelectorModal,
    } = useModal('application-selector-modal');

    return (
        <Modal
            id="application-selector-modal"
            className={styles['application-selector-modal']}
            visible={isApplicationSelectorModalVisible}
            zIndex={900}
            sx={{ width: ['100%'], maxWidth: 700 }}
            closeOnEscape
            onClose={() => {
                closeApplicationSelectorModal();
            }}
        >
            <ApplicationSelector />
        </Modal>
    );
};
