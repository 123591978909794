export const SYNC_SUB_NOTES_MESSAGE = {
    WARNING: 'syncSubNotesWarning',
    ERROR: 'syncSubNotesError',
    SUCCESS: 'syncSubNotesSuccess',
};

export enum SyncSubNotesState {
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    EMPTY = 'EMPTY',
}
