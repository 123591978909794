import { Flex } from '@nestoca/ui';

import { ScrollArea } from 'components/scroll-area';
import { Application } from 'types/application';

import { ApplicationItem } from './application-item';
import styles from './application-selector-body.module.scss';

type ApplicationSelectorBodyProps = {
    applications: Application[];
    handleSetCurrentApplicationId: (applicationId: number) => void;
};

export const ApplicationSelectorBody = ({
    applications,
    handleSetCurrentApplicationId,
}: ApplicationSelectorBodyProps) => {
    return (
        <ScrollArea className={styles['scroll-area']}>
            <Flex
                className={styles['application-items']}
                direction="column"
                gap={4}
                as="ul"
            >
                {applications.map((application) => (
                    <ApplicationItem
                        key={application.id}
                        application={application}
                        handleSetCurrentApplicationId={
                            handleSetCurrentApplicationId
                        }
                    />
                ))}
            </Flex>
        </ScrollArea>
    );
};
