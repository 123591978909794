import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Box, Flex, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { Avatar } from 'grommet';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
    BsArrowsAngleContract,
    BsArrowsAngleExpand,
    BsGear,
} from 'react-icons/bs';
import { IoIosLogOut } from 'react-icons/io';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ApplicationSelectorModal } from 'components/application-selector-modal';
import { Tooltip } from 'components/tooltip';
import { useAccountRid } from 'providers/account-rid/use-account-rid';
import { useClient } from 'providers/auth0';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import {
    currentApplicationIdState,
    getMainApplicant,
} from 'store/applications';
import {
    isSidebarOpenState,
    isWindowSmallerThanLargeBreakpointState,
} from 'store/ui';
import { useAnalytics } from 'utils/use-analytics';

import { FooterButton } from './footer-button';
import styles from './primary-sidebar.module.scss';

export type ButtonOptions = {
    id: string;
    dataTestId?: string;
    label: string | null;
    icon: React.ReactElement;
    disabled?: boolean;
    tooltipContent: React.ReactNode;
    onClick: () => void;
};

type Props = {
    isSidebarOpen: boolean;
};

export const Footer = ({ isSidebarOpen }: Props) => {
    const router = useRouter();
    const { i18n, setLanguage } = useI18n();
    const { logout } = useAuth0();
    const { addError } = useAnalytics();
    const { client } = useClient();
    const { resetAccountRid } = useAccountRid();

    const setIsSidebarOpen = useSetRecoilState(isSidebarOpenState);
    const isWindowSmallerThanLargeBreakpoint = useRecoilValue(
        isWindowSmallerThanLargeBreakpointState
    );

    const toggleSidebar = () => {
        setIsSidebarOpen((currentValue: boolean) => !currentValue);
    };

    const { open: openSelectApplication } = useModal<never>(
        'application-selector-modal'
    );

    const languages = {
        fr: 'languageSwitcher.fr',
        en: 'languageSwitcher.en',
    };

    const applicationId = useRecoilValue(currentApplicationIdState);

    const mainApplicant = useRecoilValue(getMainApplicant(applicationId));

    const mainApplicantFullName = `${mainApplicant?.firstName || ''} ${
        mainApplicant?.lastName || ''
    }`;

    const handleLogout = () => {
        if (client?.rid === undefined) {
            addError('undefined RID');
        }

        resetAccountRid();

        logout({
            logoutParams: {
                returnTo: `${window.location.origin}/?accountRid=${client.rid}`,
            },
        });
    };

    const getAvatarContent = () =>
        `${mainApplicant?.firstName?.charAt(0).toUpperCase() || ''}${
            mainApplicant?.lastName?.charAt(0).toUpperCase() || ''
        }`;

    const buttonsOptions: ButtonOptions[] = [
        {
            id: 'language',
            label: i18n.locale === 'en' ? 'FR' : 'EN',
            icon: null,
            tooltipContent: i18n._(
                languages[i18n.locale === 'en' ? 'fr' : 'en']
            ),
            onClick: () => setLanguage(i18n.locale === 'en' ? 'fr' : 'en'),
        },
        {
            id: 'applications-settings',
            dataTestId: 'appSelector',
            label: null,
            icon: (
                <BsGear
                    key="icon-gear"
                    title={null}
                    size={16}
                    color="var(--color-brand)"
                />
            ),
            tooltipContent: i18n._('applicationsSettings'),
            onClick: () => openSelectApplication(),
        },
        {
            id: 'logout',
            label: null,
            icon: (
                <IoIosLogOut
                    key="icon-logout"
                    title={null}
                    size={20}
                    color="var(--color-brand)"
                />
            ),
            tooltipContent: i18n._('Logout'),
            onClick: () => handleLogout(),
        },
        {
            id: isSidebarOpen ? 'collapse' : 'expand',
            label: null,
            icon: isSidebarOpen ? (
                <BsArrowsAngleContract
                    key="icon-collapse"
                    title={null}
                    size={16}
                    color="var(--color-brand)"
                />
            ) : (
                <BsArrowsAngleExpand
                    key="icon-expand"
                    title={null}
                    size={16}
                    color="var(--color-brand)"
                />
            ),
            disabled: isWindowSmallerThanLargeBreakpoint,
            tooltipContent: i18n._(isSidebarOpen ? 'collapse' : 'expand'),
            onClick: () => toggleSidebar(),
        },
    ];

    return (
        <>
            <Flex className={styles.footer} direction="column">
                <Tooltip
                    content={mainApplicantFullName}
                    disabled={isSidebarOpen}
                    offset={[0, 18]}
                    placement="right"
                >
                    <Box className={styles.link_wrapper}>
                        <Link href="/profile" legacyBehavior>
                            <a
                                className={cn([styles.link], {
                                    [styles.link__active]:
                                        router.pathname === '/profile',
                                })}
                            >
                                <Avatar size="36px" background="accent-4">
                                    <Typography size={0} weight={7}>
                                        {getAvatarContent()}
                                    </Typography>
                                </Avatar>
                                {isSidebarOpen && (
                                    <Flex direction="column" gap={0}>
                                        <Typography
                                            className={
                                                styles.currentlyWorkingOn
                                            }
                                            size="00"
                                            height={1}
                                        >
                                            {i18n._('fileWorkingOn')}
                                        </Typography>
                                        <Typography
                                            weight={7}
                                            style={{ fontSize: '12px' }}
                                        >
                                            {mainApplicantFullName}
                                        </Typography>
                                    </Flex>
                                )}
                            </a>
                        </Link>
                    </Box>
                </Tooltip>
                <Flex
                    className={styles.actions}
                    direction={isSidebarOpen ? 'row' : 'column'}
                    align="stretch"
                    gap={isSidebarOpen ? 2 : 3}
                >
                    {buttonsOptions.map((buttonOptions) => (
                        <FooterButton
                            isSidebarOpen={isSidebarOpen}
                            key={buttonOptions.id}
                            buttonOptions={buttonOptions}
                        />
                    ))}
                </Flex>
            </Flex>
            <ApplicationSelectorModal />
        </>
    );
};
