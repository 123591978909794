import { Trans } from '@lingui/react';
import { Button, isObject, Modal } from '@nestoca/ui';
import { VscSync } from 'react-icons/vsc';
import { Flex } from 'reflexbox';
import styled from 'styled-components';

import { bySection } from 'components/change-log-sidebar/change-log';
import { OverflowText } from 'components/change-log-sidebar/display-change';
import { Grid } from 'components/grid/grid';
import { Text } from 'components/text/text';
import { formatAddress } from 'utils';

const ModalElement = styled(Modal)`
    padding: 0;
    width: var(--size-md);
    max-width: var(--size-md);
    max-height: calc(var(--size-sm) + var(--size-5));
`;

const NotesRow = styled(Grid)`
    align-items: center;
    font-size: var(--font-size-2);
    &:nth-child(even) {
        background-color: var(--color-light-6);
    }
`;

const Cell = styled.div`
    padding: var(--spacing-3);
    &:not(:last-child) {
        border-right: 1px solid var(--color-gray-200);
    }
`;

const Section = styled(Flex)`
    flex-direction: column;
    margin-bottom: var(--spacing-5);
    overflow: hidden;
    width: 100%;
    background-color: white;
    border-radius: var(--border-radius-2);
    box-shadow: var(--shadow-small), var(--shadow-medium);
`;

const SectionName = styled(Text)`
    margin: var(--spacing-5) var(--spacing-3);
    font-size: var(--font-size-2);
    font-weight: var(--font-weight-6);
`;

const Footer = styled(Modal.Footer)`
    padding: 'var(--size-5)';
    margin: 0;
    display: 'flex';
    justify-content: 'flex-end';
`;

export const ReSyncSubNotesModal = ({
    changeLog,
    isModalOpen,
    setIsModalOpen,
    onResync,
    isLoading,
}) => {
    const sectionsChanges = bySection(changeLog);
    return (
        <ModalElement
            visible={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            overlay
        >
            <Modal.Header css={{ marginBottom: 0, alignItems: 'start' }}>
                <Flex flexDirection="column" padding="var(--spacing-5)">
                    <Text
                        tx="syncSubnotesModalTitle"
                        fontSize="var(--font-size-4)"
                        fontWeight="var(--font-weight-7)"
                    />
                    <Text
                        tx="syncSubnotesModalSubtitle"
                        fontSize="var(--font-size-1)"
                    />
                </Flex>
            </Modal.Header>
            <Modal.Body>
                <Flex
                    flexDirection="column"
                    alignItems="center"
                    backgroundColor="var(--color-light-6)"
                    padding="var(--spacing-4)"
                >
                    {changeLog &&
                        Object.keys(sectionsChanges).map((changes) => {
                            return (
                                <Section
                                    marginBottom="var(--spacing-5)"
                                    key={`${changes}-change-log`}
                                >
                                    <SectionName tx={changes} />
                                    {sectionsChanges[changes].map((change) => {
                                        return (
                                            <SubNotesRow
                                                change={change}
                                                key={`${change.type}-${change.to}-${changes}`}
                                            />
                                        );
                                    })}
                                </Section>
                            );
                        })}
                </Flex>
            </Modal.Body>
            <Footer
                css={{
                    padding: 'var(--size-5)',
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    variant="primary"
                    css={{ borderRadius: 'var(--border-radius-2)' }}
                    onClick={onResync}
                    isLoading={isLoading}
                >
                    <Trans id="reSync" />
                    <VscSync />
                </Button>
            </Footer>
        </ModalElement>
    );
};

const SubNotesRow = ({ change }) => {
    let { path, type, from, to } = change;

    if (
        !!to &&
        (to.unit ||
            to.streetNumber ||
            to.street ||
            to.city ||
            to.stateCode ||
            to.countryCode ||
            to.postalCode)
    ) {
        to = formatAddress(to);
    }

    if (isObject(to)) {
        to = JSON.stringify(to, null, 2);
    }

    if (isObject(from)) {
        from = JSON.stringify(from, null, 2);
    }

    return (
        <NotesRow
            key={`${change.type}-${change.to}`}
            gridTemplateColumns={'1fr 1fr 1fr auto'}
            gridAutoFlow="row"
        >
            <Cell>
                <Text tx={path[path.length - 1]} />
            </Cell>
            <Cell>
                <Text tx={type} />
            </Cell>
            <Cell>
                <Flex
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                >
                    <div>
                        <OverflowText text={from} former />
                    </div>
                    <OverflowText text={to} />
                </Flex>
            </Cell>
            {/* needs BE work to support this */}
            {/* <Cell>
                <Chip
                    label={i18n._('outOfSync')}
                    leadingIcon={<BsExclamationDiamond size="16" />}
                ></Chip>
            </Cell> */}
        </NotesRow>
    );
};
