import React from 'react';

import { Box, NavigationLink } from '@nestoca/ui';
import Link from 'next/link';

import { Tooltip } from 'components/tooltip';

import { LinkOptions } from './content';

type Props = {
    linkOptions: LinkOptions;
};

export const ContentLink = ({ linkOptions }: Props) => {
    const {
        dataTestId,
        href,
        disabled,
        visible = true,
        label,
        icon,
        active,
        tooltipDisabled,
        tooltipContent,
        warning,
        onClick,
    } = linkOptions;

    return (
        visible && (
            <Tooltip
                content={tooltipContent}
                disabled={tooltipDisabled}
                offset={[0, 18]}
                placement="right"
            >
                <Box as="li">
                    <Link href={href} legacyBehavior>
                        <NavigationLink
                            data-testid={dataTestId}
                            active={active}
                            disabled={disabled}
                            icon={icon}
                            onClick={onClick || null}
                            warning={warning}
                        >
                            {label}
                        </NavigationLink>
                    </Link>
                </Box>
            </Tooltip>
        )
    );
};
