import { BannerProps } from '@nestoca/ui';

import { SyncSubNotesState } from 'constants/banner-notifications';

export const getSyncSubmissionNotesBannerVariant = (
    syncSubmissionNotesState: SyncSubNotesState
): BannerProps['variant'] => {
    const { ERROR, WARNING, SUCCESS } = SyncSubNotesState;

    switch (syncSubmissionNotesState) {
        case ERROR:
            return 'error';
        case WARNING:
            return 'warning';
        case SUCCESS:
            return 'success';
        default:
            return 'info';
    }
};
