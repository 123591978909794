import { atom } from 'recoil';

import { persistAtom } from 'store/persist-atom';

import type { IncomeIncludedFieldName } from 'types/applicant';
import type { PropertyIncludedFieldName } from 'types/property';

export const isSidebarOpenState = atom({
    key: 'IsSidebarOpenState',
    default: true,
    effects_UNSTABLE: [persistAtom],
});

export const isWindowSmallerThanLargeBreakpointState = atom({
    key: 'isWindowSmallerThanLargeBreakpoint',
    default: false,
});

export const isContextualSidebarOpenState = atom({
    key: 'isContextualSidebarOpenState',
    default: false,
    effects_UNSTABLE: [persistAtom],
});

export const isQualificationToolOpenState = atom({
    key: 'isQualificationToolOpenState',
    default: true,
    effects_UNSTABLE: [persistAtom],
});

export const isRatesFilterOpenState = atom({
    key: 'isRatesFilterOpenState',
    default: true,
    effects_UNSTABLE: [persistAtom],
});

export const includedInLoadingState = atom<
    (PropertyIncludedFieldName | IncomeIncludedFieldName | string | number)[]
>({
    key: 'includedInLoadingState',
    default: [],
});
