import { useTenantFlags } from '@nestoca/multi-tenant';
import { Button, Flex } from '@nestoca/ui';
import { useRouter } from 'next/router';
import { BsFiletypeCsv, BsPlus } from 'react-icons/bs';

import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import { useCanadaLifeImporterRights } from 'store/rights';

import styles from './application-selector-footer.module.scss';

export const ApplicationSelectorFooter = () => {
    const { i18n } = useI18n();

    const { basePath, push } = useRouter();

    const { enableApplicationCreationInternal } = useTenantFlags();

    const { close: closeApplicationSelector } = useModal(
        'application-selector-modal'
    );

    const hasCanadaLifeImporterRights = useCanadaLifeImporterRights();

    // Open a new tab with the canada life importer page
    const handleRedirectToCanadaLifeImporter = () => {
        window.open(`${basePath}/canada-life-importer`);
    };

    const handleCreateApplication = () => {
        closeApplicationSelector();

        push('/create-application');
    };

    if (!hasCanadaLifeImporterRights && !enableApplicationCreationInternal)
        return null;

    return (
        <Flex
            className={styles['application-selector-footer']}
            align="center"
            gap={3}
            as="footer"
        >
            {hasCanadaLifeImporterRights && (
                <Button
                    size="medium"
                    variant="secondary"
                    rightIcon={<BsFiletypeCsv size={20} />}
                    onClick={handleRedirectToCanadaLifeImporter}
                >
                    {i18n._('canadaLifeImporter')}
                </Button>
            )}
            {enableApplicationCreationInternal && (
                <Button
                    data-testid="createApplication"
                    size="medium"
                    rightIcon={<BsPlus size={20} />}
                    onClick={handleCreateApplication}
                >
                    {i18n._('createApplication.submit')}
                </Button>
            )}
        </Flex>
    );
};
