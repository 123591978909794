import React from 'react';

import { ButtonIcon, Chip, Flex, Typography } from '@nestoca/ui';
import { GrClose } from 'react-icons/gr';

import { useI18n } from 'providers/i18n/use-i18n';

import styles from './popup.module.scss';

type Props = {
    nbOfNotifications: number;
    onHideNotifications: () => void;
};

export const PopupHeader = ({
    nbOfNotifications,
    onHideNotifications,
}: Props) => {
    const { i18n } = useI18n();

    return (
        <Flex
            className={styles.popup__header}
            align="center"
            justify="between"
            gap={4}
            as="header"
        >
            <Flex align="center" gap={3}>
                <Typography size={0} weight={6}>
                    {i18n._('notifications')}
                </Typography>
                <Chip size="small" label={String(nbOfNotifications)} />
            </Flex>
            <ButtonIcon
                aria-label="close-notifications-button"
                size="small"
                variant="ghost"
                icon={<GrClose size={12} />}
                onClick={onHideNotifications}
            />
        </Flex>
    );
};
