import { Flex, Typography } from '@nestoca/ui';
import { BsWindowDock } from 'react-icons/bs';

import { useClient } from 'providers/auth0';
import { useI18n } from 'providers/i18n/use-i18n';

import styles from './application-selector-header.module.scss';

export const ApplicationSelectorHeader = () => {
    const { i18n } = useI18n();

    const { client } = useClient();

    return (
        <Flex
            className={styles['application-selector-header']}
            align="center"
            gap={3}
            as="header"
        >
            <BsWindowDock />
            <Flex direction="column">
                <Typography size="00">
                    {i18n._('manageApplications')}
                </Typography>
                <Typography weight={7}>
                    {i18n._({ id: 'clientApplications', values: client })}
                </Typography>
            </Flex>
        </Flex>
    );
};
