import React, { Suspense } from 'react';

import { Skeleton, Sidebar } from '@nestoca/ui';
import cn from 'classnames';

import { ScrollArea } from 'components/scroll-area';
import { WithIsSidebarOpen } from 'components/sidebar/types';

import { Content } from './content';
import { Footer } from './footer';
import { Header } from './header';
import styles from './primary-sidebar.module.scss';

const PrimarySidebarSkeleton = ({ isSidebarOpen }: WithIsSidebarOpen) => (
    <Sidebar
        className={cn([styles.sidebar], {
            [styles.sidebar__narrow]: !isSidebarOpen,
        })}
        narrow={!isSidebarOpen}
    >
        <Sidebar.Header>
            <Header />
        </Sidebar.Header>
        <Sidebar.Content>
            <ScrollArea>
                <Skeleton animate count={10} height={40} />
            </ScrollArea>
        </Sidebar.Content>
    </Sidebar>
);

export const PrimarySidebar = ({ isSidebarOpen }: WithIsSidebarOpen) => (
    <Suspense
        fallback={<PrimarySidebarSkeleton isSidebarOpen={isSidebarOpen} />}
    >
        <Sidebar
            className={cn([styles.sidebar], {
                [styles.sidebar__narrow]: !isSidebarOpen,
            })}
            narrow={!isSidebarOpen}
        >
            <Sidebar.Header>
                <Header />
            </Sidebar.Header>
            <Sidebar.Content>
                <Content isSidebarOpen={isSidebarOpen} />
            </Sidebar.Content>
            <Sidebar.Footer>
                <Footer isSidebarOpen={isSidebarOpen} />
            </Sidebar.Footer>
        </Sidebar>
    </Suspense>
);
