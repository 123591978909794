import React from 'react';

import { Flex } from '@nestoca/ui';

import { NotificationOptions } from 'components/layout/banner-notifications';

import { PopupContent } from './popup-content';
import { PopupHeader } from './popup-header';
import styles from './popup.module.scss';

type Props = {
    notificationsOptions: NotificationOptions[];
    nbOfNotifications: number;
    onHideNotifications: () => void;
};

export const Popup = ({
    notificationsOptions,
    nbOfNotifications,
    onHideNotifications,
}: Props) => {
    return (
        <Flex className={styles.popup} direction="column">
            <PopupHeader
                nbOfNotifications={nbOfNotifications}
                onHideNotifications={onHideNotifications}
            />
            <PopupContent notificationsOptions={notificationsOptions} />
        </Flex>
    );
};
