import { Button, Flex, Typography } from '@nestoca/ui';
import { useRouter } from 'next/router';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useI18n } from 'providers/i18n/use-i18n';
import {
    applicationProblemsSidebar,
    getHasProblems,
    sidebarNotification,
} from 'store/applications';

import styles from './sidebar-problem-notification.module.scss';

type Props = {
    closeNotifications: () => void;
    applicationId: number;
};

export function ProblemSidebarNotification({
    closeNotifications,
    applicationId,
}: Props) {
    const { i18n } = useI18n();

    const router = useRouter();

    const setProblemsSidebarStatus = useSetRecoilState(
        applicationProblemsSidebar
    );

    const setProblemsSidebarNotification =
        useSetRecoilState(sidebarNotification);

    const handleRedirect = () => {
        setProblemsSidebarStatus((currentValue) => ({
            ...currentValue,
            isSidebarOpen: true,
            sidebarView: 'missingFields',
        }));

        router.push(`/applications/${applicationId}`);
    };

    const handleShowProblemsSidebar = () => {
        closeNotifications();
        handleRedirect();
    };

    const hasProblems = useRecoilValue(
        getHasProblems({
            applicationId,
        })
    );

    const handleRefreshProblems = async () => {
        if (!hasProblems) {
            setProblemsSidebarNotification(false);
            closeNotifications();
            return;
        }
        handleShowProblemsSidebar();
    };

    return (
        <Flex
            className={styles['sidebar-problem-notification ']}
            align="center"
            gap={3}
        >
            <Typography size={0} height={2}>
                {i18n._('problemsSidebar.title')}
            </Typography>
            <Flex align="center" gap={1}>
                <Button
                    className={styles['link-button']}
                    size="small"
                    variant="link"
                    onClick={handleShowProblemsSidebar}
                >
                    {i18n._(`problemsSidebar.viewFields`)}
                </Button>
                |
                <Button
                    className={styles['link-button']}
                    size="small"
                    variant="link"
                    onClick={handleRefreshProblems}
                >
                    {i18n._(`problemsSidebar.refresh`)}
                </Button>
            </Flex>
        </Flex>
    );
}
