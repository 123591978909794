import React from 'react';

import { useTenant } from '@nestoca/multi-tenant';
import Link from 'next/link';
import { useRecoilValue } from 'recoil';

import { useI18n } from 'providers/i18n/use-i18n';
import { isSidebarOpenState } from 'store/ui';

export const Header = () => {
    const {
        i18n: { locale },
    } = useI18n();
    const tenant = useTenant();
    const isSidebarOpen = useRecoilValue(isSidebarOpenState);

    return (
        <Link href="/">
            {isSidebarOpen ? (
                <img
                    alt={`logo ${tenant?.displayName}`}
                    src={tenant?.logo?.primary[locale]?.url}
                />
            ) : (
                <img
                    alt={`logo ${tenant?.displayName}`}
                    src={tenant?.logo?.secondary[locale]?.url}
                />
            )}
        </Link>
    );
};
