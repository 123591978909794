import { useRecoilValue, useRecoilState } from 'recoil';

import {
    applicationListState,
    currentApplicationIdState,
} from 'store/applications';

/**
 * This hook is used to check if the current application id is still valid.
 * If the current application id is not valid, it will set the current application id to undefined.
 *
 * This is a temporary fix for the issue where the current application id
 * is not updated when we switch between on behalf users
 *
 * A ticket was created (OG-2211) to investigate this in more detail
 *
 * @see https://nestoca.atlassian.net/browse/OG-2210
 * @see https://nestoca.atlassian.net/browse/OG-2211
 */
export const useMatchApplicationId = () => {
    const applications = useRecoilValue(applicationListState);
    const [currentApplicationId, setCurrentApplicationIdState] = useRecoilState(
        currentApplicationIdState
    );

    const isInvalid = !applications.find(
        ({ id }) => id === currentApplicationId
    );

    if (isInvalid) {
        setCurrentApplicationIdState(undefined);
    }
    return { isInvalid };
};
