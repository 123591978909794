import React from 'react';

import { Banner, Typography } from '@nestoca/ui';

import { NotificationOptions } from 'components/layout/banner-notifications';
import { ScrollArea } from 'components/scroll-area';

type Props = {
    notificationsOptions: NotificationOptions[];
};

export const PopupContent = ({ notificationsOptions }: Props) => {
    return (
        <ScrollArea>
            {notificationsOptions.map((notificationOptions) => {
                const {
                    id,
                    children,
                    variant = 'info',
                    visible = true,
                } = notificationOptions;

                return (
                    <Banner key={id} variant={variant} visible={visible}>
                        {typeof children === 'string' ? (
                            <Typography size={0} height={2}>
                                {children}
                            </Typography>
                        ) : (
                            children
                        )}
                    </Banner>
                );
            })}
        </ScrollArea>
    );
};
