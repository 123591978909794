import React, { useCallback } from 'react';
import type { PropsWithChildren } from 'react';

import { Box, Flex } from '@nestoca/ui';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { BannerNotifications } from 'components/layout/banner-notifications';
import { PrimarySidebar } from 'components/sidebar/primary-sidebar';
import { WithIsSidebarOpen } from 'components/sidebar/types';
import { BREAKOUT_VALUE } from 'constants/appConstants';
import { currentApplicationIdState } from 'store/applications';
import {
    isSidebarOpenState,
    isWindowSmallerThanLargeBreakpointState,
} from 'store/ui';
import useIsomorphicLayoutEffect from 'utils/use-isomorphic-layout-effect';

import { useMatchApplicationId } from '../useMatchApplicationId';

import styles from './layout.module.scss';

type LayoutProps = {
    header?: React.ReactElement;
    primarySidebar?: React.ReactElement<WithIsSidebarOpen>;
    secondarySidebar?: React.ReactElement<WithIsSidebarOpen>;
    hideBannerNotifications?: boolean;
};

export const LayoutWithSidebar = ({
    children,
    header,
    primarySidebar: customPrimarySidebar,
    secondarySidebar,
    hideBannerNotifications = false,
}: PropsWithChildren<LayoutProps>) => {
    const [isSidebarOpen, setIsSidebarOpen] =
        useRecoilState(isSidebarOpenState);

    const setIsWindowSmallerThanLargeBreakpoint = useSetRecoilState(
        isWindowSmallerThanLargeBreakpointState
    );

    const applicationId = useRecoilValue(currentApplicationIdState);

    useMatchApplicationId();

    const primarySidebar = customPrimarySidebar || (
        <PrimarySidebar isSidebarOpen={isSidebarOpen} />
    );

    const handleWindowResize = useCallback(() => {
        const windowWidth = window.innerWidth;
        const breakoutValue = BREAKOUT_VALUE.LG;

        if (windowWidth <= breakoutValue) {
            setIsSidebarOpen(false);
            setIsWindowSmallerThanLargeBreakpoint(true);
        } else {
            setIsWindowSmallerThanLargeBreakpoint(false);
        }
    }, [setIsSidebarOpen, setIsWindowSmallerThanLargeBreakpoint]);

    useIsomorphicLayoutEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, [handleWindowResize]);

    return (
        <Flex id="layout" data-testid="layout" className={styles.layout}>
            <Flex className={styles.sidebars} shrink={0}>
                {primarySidebar}
                {secondarySidebar && secondarySidebar}
            </Flex>
            <Flex className={styles.main} direction="column" as="main">
                {header && (
                    <Box className={styles.header} as="header">
                        {header}
                    </Box>
                )}
                <Box className={styles.section} as="section">
                    <Flex
                        id="main-content"
                        className={styles.content}
                        direction="column"
                        gap={5}
                    >
                        {!!applicationId && !hideBannerNotifications && (
                            <BannerNotifications
                                applicationId={applicationId}
                            />
                        )}
                        {children}
                    </Flex>
                </Box>
            </Flex>
        </Flex>
    );
};
