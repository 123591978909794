import { Box, Chip, Flex, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { APPLICATION_STATE_CHIP_VARIANT } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import { currentApplicationIdState } from 'store/applications';
import { useRefreshApplicationById } from 'store/applications';
import { Application } from 'types/application';
import { formatAddress } from 'utils';

import styles from './application-item.module.scss';

type ApplicationItemProps = {
    application: Application;
    handleSetCurrentApplicationId: (applicationId: number) => void;
};

export const ApplicationItem = ({
    application,
    handleSetCurrentApplicationId,
}: ApplicationItemProps) => {
    const { i18n } = useI18n();

    const { push } = useRouter();

    const { isSdr } = useFlags();

    const { close: closeApplicationSelector } = useModal(
        'application-selector-modal'
    );

    const { applicationState, id, property, type } = application;

    const currentApplicationId = useRecoilValue(currentApplicationIdState);

    const refreshApplication = useRefreshApplicationById(id);

    const isApplicationActive = id === currentApplicationId;

    const hasPostalCode = property?.address?.postalCode;

    const handleSelectedApplication = async () => {
        try {
            await refreshApplication();

            if (application.id !== currentApplicationId) {
                handleSetCurrentApplicationId(application.id);
                closeApplicationSelector();

                if (application.applicationState !== 'CREATED') {
                    push('/');
                    return;
                }
                if (isSdr) {
                    push(`/sdr`);
                    return;
                }
                push(`/applications/${application.id}`);
                return;
            }
            closeApplicationSelector();
        } catch (error) {
            toast.error(
                <Typography size={0}>
                    {i18n._('currentApplication.update.failed')}
                </Typography>,
                {
                    autoClose: 5000,
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        }
    };

    return (
        <Flex
            className={cn(styles['application-item'], {
                [styles['application-item--active']]: isApplicationActive,
            })}
            as="li"
        >
            <Box
                className={styles['application-item__button']}
                data-testid={`application-${id}`}
                onClick={handleSelectedApplication}
                as="button"
            >
                <Flex direction="column" align="start" gap={1}>
                    <Typography size={0} weight={7}>{`${id} - ${i18n._(
                        `type.${type}`
                    )}`}</Typography>
                    <Typography weight={6} style={{ fontSize: '12px' }}>
                        {hasPostalCode
                            ? formatAddress(property?.address)
                            : i18n._('noSubjectProperty')}
                    </Typography>
                </Flex>
                <Chip
                    label={i18n._(applicationState)}
                    variant={APPLICATION_STATE_CHIP_VARIANT[applicationState]}
                    variantStyle="inverted"
                />
            </Box>
        </Flex>
    );
};
