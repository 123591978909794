import { isObject } from '@nestoca/ui';
import { atomFamily, selectorFamily, useSetRecoilState } from 'recoil';

import { client as apiClient } from 'libs/api';
import { DecisionEngineReportDetails } from 'types/decision-engine';

export const defaultDecisionEngineReportDetailsState: DecisionEngineReportDetails =
    {
        applicationId: null,
        isReportExist: false,
        hasNotification: false,
    };

const decisionEngineReportDetailsRequestIdState = atomFamily({
    key: 'DecisionEngineReportDetailsRequestId',
    default: 0,
});

export const getDecisionEngineReportDetails = selectorFamily({
    key: 'GetDecisionEngineReportDetails',
    get:
        (applicationId: number) =>
        async ({ get }) => {
            try {
                get(decisionEngineReportDetailsRequestIdState(applicationId));

                const { data } = await apiClient.getDecisionEngineReportDetails(
                    applicationId
                );

                if (!isObject(data)) {
                    throw new Error(
                        'Decision report details response is malformed'
                    );
                }

                return data;
            } catch (error) {
                return defaultDecisionEngineReportDetailsState;
            }
        },
});

export const useRefreshDecisionEngineReportDetails = (
    applicationId: number
) => {
    const setRequestID = useSetRecoilState(
        decisionEngineReportDetailsRequestIdState(applicationId)
    );

    return () => {
        setRequestID((requestId) => requestId + 1);
    };
};
