import React from 'react';

import { Button } from '@nestoca/ui';

import { Tooltip } from 'components/tooltip';

import { ButtonOptions } from './footer';

type Props = {
    isSidebarOpen: boolean;
    buttonOptions: ButtonOptions;
};

export const FooterButton = ({ isSidebarOpen, buttonOptions }: Props) => {
    const { dataTestId, label, icon, onClick, disabled, tooltipContent } =
        buttonOptions;

    return (
        <Tooltip
            content={tooltipContent}
            offset={isSidebarOpen ? [0, 12] : [0, 18]}
            placement="right"
        >
            <Button
                data-testid={dataTestId}
                disabled={disabled}
                size="small"
                variant="ghost"
                leftIcon={icon}
                onClick={onClick}
            >
                {label}
            </Button>
        </Tooltip>
    );
};
