import React from 'react';

import { isObject } from '@nestoca/ui';
import { Flex } from 'reflexbox';

import { Grid } from 'components/grid/grid';
import { Text } from 'components/text/text';
import { formatAddress } from 'utils';

export const OverflowText = ({
    text,
    former,
}: {
    text: string;
    former?: boolean;
}) => (
    <div
        css={{
            textOverflow: 'ellipsis',
            fontSize: '14px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '120px',
            textDecoration: former && 'line-through',
            fontWeight: former ? '400' : '600',
            ':hover': {
                overflow: 'visible',
                whiteSpace: 'normal',
                width: '100%',
            },
        }}
    >
        {text}
    </div>
);

export const DisplayChange = ({ change }) => {
    let { path, type, from, to } = change;
    if (
        !!to &&
        (to.unit ||
            to.streetNumber ||
            to.street ||
            to.city ||
            to.stateCode ||
            to.countryCode ||
            to.postalCode)
    ) {
        to = formatAddress(to);
    }

    if (isObject(to)) {
        to = JSON.stringify(to, null, 2);
    }

    if (isObject(from)) {
        from = JSON.stringify(from, null, 2);
    }

    return (
        <Grid
            gridGap={15}
            width="90%"
            gridTemplateColumns={'160px 80px 180px'}
            gridAutoFlow="row"
            alignItems="center"
            fontSize="16px"
            css={{ borderBottom: '1px solid #F5F5F5' }}
        >
            <div>
                <Text tx={path[path.length - 1]} />
            </div>
            <Text tx={type} />
            <Flex flexDirection="column" alignItems="center" textAlign="center">
                <div>
                    <OverflowText text={from} former />
                </div>
                <OverflowText text={to} />
            </Flex>
        </Grid>
    );
};
