import { useEffect, useState } from 'react';
import React from 'react';

import { i18n } from '@lingui/core';
import { Box, Button, Flex, Typography } from '@nestoca/ui';

import { ReSyncSubNotesModal } from 'components/application-review/resync-subnotes-modal';
import { SYNC_SUB_NOTES_MESSAGE } from 'constants/banner-notifications';
import { SyncSubNotesState } from 'constants/banner-notifications';
import { client as apiClient } from 'libs/api';
import { useRefreshApplicationMortgage } from 'store/applications';
import { useRefreshSubmissionNotes } from 'store/submission-notes';
import { SubmissionNoteType } from 'types/submission-notes';

import styles from './banner-content.module.scss';

type Props = {
    applicationId: number;
    submissionNotes: SubmissionNoteType;
    syncSubmissionNotesState: SyncSubNotesState;
    setSyncSubmissionNotesState: React.Dispatch<
        React.SetStateAction<SyncSubNotesState>
    >;
};

export const SyncSubmissionNotes = ({
    applicationId,
    submissionNotes,
    syncSubmissionNotesState,
    setSyncSubmissionNotesState,
}: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const { EMPTY, SUCCESS } = SyncSubNotesState;

        let timeout: NodeJS.Timeout;

        if (syncSubmissionNotesState === SUCCESS) {
            timeout = setTimeout(() => {
                setSyncSubmissionNotesState(EMPTY);
            }, 3000);
        }

        return () => clearTimeout(timeout);
    }, [syncSubmissionNotesState, setSyncSubmissionNotesState]);

    const refreshSubmissionNotes = useRefreshSubmissionNotes(applicationId);

    const refreshApplicationMortgage =
        useRefreshApplicationMortgage(applicationId);

    const reSyncSubmissionNotes = async () => {
        const { ERROR, SUCCESS } = SyncSubNotesState;

        try {
            setIsLoading(true);

            await apiClient.syncSubmissionNotes(
                applicationId,
                submissionNotes.id
            );
            await refreshSubmissionNotes();
            await refreshApplicationMortgage();
        } catch (error) {
            setSyncSubmissionNotesState(ERROR);
        } finally {
            setIsLoading(false);
            setIsModalOpen(false);
            setSyncSubmissionNotesState(SUCCESS);
        }
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    return (
        <Box>
            <Flex className={styles['banner-content']} align="center" gap={3}>
                <Typography size={0} height={2}>
                    {i18n._(SYNC_SUB_NOTES_MESSAGE[syncSubmissionNotesState])}
                </Typography>
                {syncSubmissionNotesState === SyncSubNotesState.WARNING && (
                    <Button
                        className={styles['link-button']}
                        size="small"
                        variant="link"
                        onClick={handleOpenModal}
                    >
                        {i18n._(`button.reSyncNotes`)}
                    </Button>
                )}
            </Flex>
            <ReSyncSubNotesModal
                changeLog={submissionNotes.diff}
                isLoading={isLoading}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onResync={reSyncSubmissionNotes}
            />
        </Box>
    );
};
