import React from 'react';

import SVG from 'components/SVG';

export const PersonRolodex = ({ fillColor = 'currentColor', ...rest }: any) => (
    <SVG
        width="24"
        height="24"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M8.00002 9.05001C9.38073 9.05001 10.5 7.93072 10.5 6.55001C10.5 5.1693 9.38073 4.05001 8.00002 4.05001C6.61931 4.05001 5.50002 5.1693 5.50002 6.55001C5.50002 7.93072 6.61931 9.05001 8.00002 9.05001Z"
            fill={fillColor}
        />
        <path
            d="M1 1C0.447715 1 0 1.44772 0 2V13C0 13.5523 0.447715 14 1 14H1.5C1.77614 14 2 13.7761 2 13.5C2 13.2239 2.22386 13 2.5 13C2.77614 13 3 13.2239 3 13.5C3 13.7761 3.22386 14 3.5 14H12.5C12.7761 14 13 13.7761 13 13.5C13 13.2239 13.2239 13 13.5 13C13.7761 13 14 13.2239 14 13.5C14 13.7761 14.2239 14 14.5 14H15C15.5523 14 16 13.5523 16 13V3C16 2.44772 15.5523 2 15 2L6.70711 2L6 1.29289C5.81246 1.10536 5.55811 1 5.29289 1H1ZM1 2L5.29289 2L6 2.70711C6.18754 2.89464 6.44189 3 6.70711 3H15V13H14.9146C14.7087 12.4174 14.1531 12 13.5 12C13.1227 12 12.778 12.1393 12.5144 12.3692C11.8862 11.2242 10.5552 10 8.00002 10C5.44479 10 4.11379 11.2242 3.48565 12.3693C3.22205 12.1393 2.87729 12 2.5 12C1.84689 12 1.29127 12.4174 1.08535 13H1V2Z"
            fill={fillColor}
        />
    </SVG>
);
