import { Flex, Skeleton } from '@nestoca/ui';

import styles from 'components/application-selector/application-selector-body/application-selector-body.module.scss';
import { ScrollArea } from 'components/scroll-area';

export const BodySkeleton = () => {
    return (
        <ScrollArea>
            <Flex
                className={styles['application-items']}
                direction="column"
                gap={3}
            >
                {[...Array(15)].map((_, index) => (
                    <Skeleton key={index} animate height={71} />
                ))}
            </Flex>
        </ScrollArea>
    );
};
